<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista vendite</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/wizard')">Nuova</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-autocomplete rounded solo v-model="userSearch" label="Utente" clearable :items="userOptions" @input="applyFilter"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <!--<v-autocomplete rounded solo v-model="kitSearch" label="Pacchetto" clearable :items="kitOptions" @input="applyFilter"></v-autocomplete>-->
                                <v-autocomplete rounded solo v-model="kitSearch" label="Pacchetto" clearable :items="kitOptions" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-autocomplete rounded solo v-model="productSearch" :disabled="!kitSearch" label="Prodotto" clearable
                                                :items="productOptions" @input="applyFilter"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="skuSearch" label="SKU Prodotto" clearable @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo type="date" v-model="fromSearch" label="Venduti dal" clearable @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo type="date" v-model="toSearch" label="Venduti fino al" clearable @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-switch rounded solo v-model="includeDraft" label="Includi ordini aperti" @change="applyFilter"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono vendite censite al momento."  :footer-props="footerProps">
                                    <template v-slot:item.user.surname="{ item }">
                                        <a :href="'./#/sells/'+item._id" ><b>{{ item.user.surname }} {{ item.user.name }}</b></a>
                                    </template>
                                    <template v-slot:item.items="{ item }">
                                      <v-tooltip v-if="item.items" bottom>
                                        <template #activator="{ on, attrs }">
                                          <a class="text-decoration-underline pointer accent--text" v-bind="attrs" v-on="on" >{{item.items.length}} prodotti</a>
                                        </template>
                                        <div v-for="p in item.items" :key="`${item._id}_${p._id}`">
                                          <div class="d-flex align-center"><v-icon class="mr-1" color="white">mdi-chevron-right</v-icon><p class="mb-0">{{p.title.it}}</p></div>
                                        </div>
                                      </v-tooltip>

                                        <span v-else>0 prodotti</span>
                                    </template>
                                    <template v-slot:item.last_update="{ item }">
                                        {{formatDate(item.last_update)}}
                                    </template>
                                    <template v-slot:item.total="{ item }">
                                        € {{ getTotal(item) }}
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <span v-if="item.status === 'draft'">Ordine aperto</span>
                                        <span v-if="item.status === 'paid'">Ordine pagato (in attesa di spedizione)</span>
                                        <span v-if="item.status === 'closed'">Ordine chiuso</span>
                                    </template>
                                    <template v-slot:item.expedition="{ item }">
                                        <span v-if="item.expedition">Sì</span>
                                        <span v-else>No</span>
                                    </template>
                                    <template v-slot:item.card="{ item }">
                                        <span v-if="item.card">Sì</span>
                                        <span v-else>No</span>
                                    </template>
                                    <template v-slot:item.home_kit="{ item }">
                                        <span v-if="item.home_kit">Sì</span>
                                        <span v-else>No</span>
                                    </template>
                                    <template v-slot:item.evaded="{ item }">
                                        <span v-if="item.evaded" class="success--text font-weight-bold">Sì</span>
                                        <span v-else class="error--text font-weight-bold">No</span>
                                    </template>
                                    <template v-slot:item.view="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'sell view', params : { id : item._id }})">
                                            <v-icon dark>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'sell form', params : { id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import {DateTime} from "luxon";
    import utils from "../utils";
    export default {
        name: "PurchaseList",
        data () {
            return {
              settings: {
                coni_card_price : 15,
                max_days_of_subscription_pause : 10,
                extra_day_of_sub_pause_price : 3
              },
                prodRefresher : 96969696,
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                userSearch : null,
                kitSearch : null,
                productSearch : null,
                skuSearch : null,
                fromSearch : null,
                toSearch : null,
                includeDraft : false,
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Utente', sortable: true, align: 'left', value : 'user.surname' },
                    { text : 'Ultimo aggiornamento', sortable: true, align: 'left', value : 'last_update' },
                    { text : 'N° prodotti', sortable: false, align: 'left', value : 'items' },
                    { text : 'Prezzo Totale', sortable: false, align: 'left', value : 'total' },
                    { text : 'Stato', sortable: false, align: 'left', value : 'status' },
                    { text : 'Spedizione', sortable: true, align: 'left', value : 'expedition' },
                    { text : 'Tessera MSP', sortable: true, align: 'left', value : 'card' },
                    { text : 'HomeKit', sortable: true, align: 'left', value : 'home_kit' },
                    { text : 'Evaso', sortable: true, align: 'left', value : 'evaded' },
                    { text : 'Vedi', sortable: false, align: 'center', value : 'view', width : 60 },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                pagination: {
                  sortBy: ["last_update"],
                  sortDesc: [true]
                },
                query : {
                    nodraft : true
                }
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile','kits','users','products']),
            userOptions() {
                return this.users.map((item)=>{
                    return { text:item.surname + ' ' + item.name, value: item._id };
                });
            },
            kitOptions() {
                return this.kits.map((item)=>{
                    return { text:item.title.it, value: item._id };
                });
            },
            productOptions() {
                return this.products.filter((item)=>{


                    if(!this.kitSearch) return false
                    return this.kitSearch === item.kit._id;
                }).map((item)=>{
                    return { text:item.title.it, value: item._id };
                });
            },
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','refreshCollection','get']),
            formatDate( date ) {
                return utils.toDateTime(date).toFormat("dd/MM/yyyy HH:mm:ss");
            },
            getTotal(item) {
                let total = 0;
                for(let i = 0; i < item.items.length; i++) {
                    total += item.items[i].price;
                }

                if(item.card) {
                  total += this.settings.coni_card_price
                }
                if(item.sub_pause_days) {
                  total += item.sub_pause_days * this.settings.extra_day_of_sub_pause_price
                }

                return total
            },
            getKitTitle( id ) {
                for(let i = 0; i < this.kits.length; i++) {
                    if(this.kits[i]._id === id) {
                        return this.kits[i].title.it;
                    }
                }
                return '-';
            },
            applyFilter() {
                this.prodRefresher++;
                this.query = {};
                this.query.user = this.userSearch ? this.userSearch : undefined;
                //this.query.kit = this.kitSearch ? this.kitSearch : undefined;
                this.query.product = this.productSearch ? this.productSearch : undefined;
                this.query.sku = this.skuSearch ? this.skuSearch : undefined;
                this.query.date_from = this.fromSearch ? this.fromSearch : undefined;
                this.query.date_to = this.toSearch ? this.toSearch : undefined;
                this.query.nodraft = !this.includeDraft;
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa vendita?', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'carts', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Vendita eliminata!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione della vendita';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione della vendita",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'carts', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento delle vendite."});
                    if(config.debug) console.error("Errore al caricamento delle vendite: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            },
          loadSettings() {
            this.get({collection : 'settings'}).then((reply)=>{
              if(reply.data) {
                this.settings = reply.data;
              }
            }).catch((err) => {
              let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
              this.sendError({message});
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          },
        },
        mounted() {
            this.refreshCollection({collection: 'kits'})
            this.refreshCollection({collection: 'users'})
            this.refreshCollection({collection: 'products'})
          this.loadSettings()
            this.refresh();
        }
    }
</script>

<style scoped>

</style>